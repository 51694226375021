import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import posthog from "posthog-js";

if (ENV.NODE_ENV === "production") {
  Sentry.init({
    dsn: ENV.SENTRY_DSN,
    release: ENV.RELEASE,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration(),
    ],
  });
}

function hydrate() {
  posthog.init(ENV.POSTHOG_API_KEY, {
    api_host: ENV.POSTHOG_API_HOST,
    loaded: () => {
      posthog.identify(ENV.USER_ID);
      posthog.people.set({
        email: ENV.USER_EMAIL,
        name: ENV.USER_NAME,
      });
    },
  });

  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  window.setTimeout(hydrate, 1);
}
